<template>
    <div>
        {{ row.item.netto | priceFormat }} {{ row.item.currency_short | currencyFormat }}
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
    },
}
</script>